body {
  margin: 0;
  background-color: #F2F3F4;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.SkillGroup {
    position: relative;
    width: 100%;
    padding: 32px 0 24px 0;

    .sg-title {
        font-weight: bold;
        color: #424949;
        font-size: calc(14px + 2vmin);
    }

    .sg-content {
        font-weight: normal;

        .content-title {
            font-size: calc(14px + 1vmin);
            font-weight: bold;
        }

        .content-description {
            font-size: calc(10px + 1vmin);
            margin: -10px 10px 10px 10px;
        }

        .content-skill-items {
            position: relative;
            // background-color: antiquewhite;
            margin: 10px 0 20px 0;

            .content-chip {
                position: relative;
                padding: 0 40px 40px 0;
                display: flex;
                justify-content: flex-end;
                flex-wrap: wrap;
            }

            .item-grid {
                position: relative;
                margin: 5px 0 0px 0;
    
                .item-top {
                    display: flex;
                    flex-direction: row;
                    justify-content: space-evenly;
                    align-items: center;
                    font-size: calc(10px + 1vmin);
                    color: #4D5656;
                    // background-color: antiquewhite;

                    .item-name {
                        font-size: calc(10px + 1vmin);
                        font-weight: bold;
                    }

                    .item-rate {
                        font-size: calc(8px + 1vmin);
                    }
                }
    
                .item-description {
                    font-size: calc(10px + 1vmin);
                    margin: 0 30px 2px 10px;
                    color: #424949;
                }

                &::before {
                    content: '';
                    background-color: #BDC3C7;
                    position: absolute;
                    top: 10px;
                    width: 0.2rem;
                    left: 1.8rem;
                    height: 90%;
                }

            }

            
        }
    }

    .skill-chip {
        margin: 2px;
    }

}