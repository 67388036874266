body {
  margin: 0;
  background-color: #F2F3F4;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

$lineColor: #BDC3C7;

.ProjectItem {
    position: relative;

    &>ul {
        &:before {
            background-color: $lineColor;
            position: absolute;
            content: '';
            top: 0px;
            width: 0.2rem;
            left: 2rem;
            height: 100%;
            margin-left: -1rem;
        }
    }

    .pic-content {
        position: relative;
        /* display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-around; */
        margin: 20px 0 10px 0;

        
        .content-skill {
            position: relative;
            padding: 0 0 40px 0;
            display: flex;
            justify-content: flex-end;
            flex-wrap: wrap;
        }

        .content-grid {
            // background-color: aqua;
            // padding: 0;
            // margin: 0;
            position: relative;
            .content-image {
                position: relative;
                min-height: 250px;
                background-color: $lineColor;
    
                
                &:hover {
                    .exit-to {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        background: rgba(0,0,0, 0.8);
                        color: white;
                    }
                }
    
                .exit-to {
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    opacity: 1;
                    display: none;
                    font-weight: bold;
                    &>a {
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                    }
                }
            }
        }

        .content-info {
            position: relative;
            padding-right: 30px;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: space-between;

            font-size: calc(8px + 1vmin);
            color: #424949;
            // background-color:aquamarine;
            

            .info-top {
                position: relative;
                width: 100%;

                .info-title {
                    font-size: calc(12px + 1vmin);
                    font-weight: bold;
                    color: #424949;
                }

                .info-subTitle {
                    position: relative;
                    font-size: calc(8px + 1vmin);
                    color: #626567;
                    margin-top: 10px;
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    align-items: flex-start;
                    flex-wrap: wrap;
                    .role {
                        margin-right: 20px;
                    }
                }
            }

            .info-detail {
                margin-left: 5px;
                margin-right: 10px;
            }
        }

        .info-time {
            position: relative;
            display: inline-block;
            margin: 5px 2px 16px 0px;
            padding: 6px 12px;
            text-decoration: none; 
            &::after {
                position: absolute;
                content: '';
                height: 2px;
                bottom: 0px;
                margin: 0 auto;
                left: -5px;
                right: 10px;
                width: 90%;
                background: $lineColor;

                -o-transition:.5s;
                -ms-transition:.5s;
                -moz-transition:.5s;
                -webkit-transition:.5s;
                transition:.5s;
            }
        }

        .timeline-point {
            background-color: rgb(255, 255, 255);
            // transition: all 0.2s ease-in 0s;
            outline:  $lineColor solid 0.5rem;
            display: inline-block;
            margin: 1rem 0.5rem 0.5rem -0.5rem;
            position: absolute;
            top: 0px;
            left: -1.15rem;
            width: 0.5rem;
            height: 0.5rem;
        }
    }

    .skill-chip {
        margin: 2px;
    }
}