body {
  margin: 0;
  background-color: #F2F3F4;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.main-page {
    min-height: 80vh;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: #282c34;
    // background-color: antiquewhite;

    .main-avatar-side {
        display: flex;
        align-items: center;
        justify-content: center;
        // background-color: aquamarine;
    }

    .main-info-side {
        position: relative;
        height: 100%;
        display: flex;
        align-items: flex-start;
        // justify-content: center;
        justify-content: space-evenly;
        flex-direction: column;

        .main-info-title {
            font-weight: bold;
            font-size: calc(18px + 5vmin);
            // margin: 0px 0 40px 0;
        }
        
        .main-info-subSide {

            .main-info-subTitle {
                font-weight: bold;
                font-size: calc(12px + 2vmin);
            }

            .main-info-detail {
                font-size: calc(10px + 1vmin);
                margin: 10px 0 10px 0;
            }
        }
    }
}